import type { JSX } from 'react';
import { SideBySide } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type SideBySideProps = SliceComponentProps<Content.SideBySideSlice>;

const SideBySideSlice = ({ slice }: SideBySideProps): JSX.Element => {
  return <SideBySide {...slice} />;
};

export default SideBySideSlice;
